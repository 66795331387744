import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import Layout from "../components/Layout";

const Index = () =>
  <Layout>
    <Helmet>
      <title>image-watch</title>
    </Helmet>
    <Hero />
  </Layout>;

export default Index;