import React from "react"
import { Text, Heading, Button, Image, Center } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

/** @type {React.FC} */
const Hero = () => {
  return (
    <>
      <Heading>Keep your Docker apps up-to-date</Heading>
      <Text>When your base images are updated, image-watch.com will send you a notification. Never miss a security patch or bug fix again!</Text>

      <Center marginY={8}><Image src="./containers640.jpg" height="476px" width="640px" /></Center>

      <Heading>Security FTW</Heading>
      <Text>Your Docker app extends a base image, which is usually a runtime of some kind (e.g., Python, Ruby, .NET, ...).</Text>
      <Text>You can subscribe to this base image in image-watch.com, and we will notify you of all updates to that base image. This includes bug fixes for your runtime <Text as="em">and</Text> security patches for your underlying OS.</Text>
      <Button as={GatsbyLink} to="/app">Get started!</Button>

      <Heading>Know what you're running in production</Heading>
      <Text>Docker base images are kinda confusing. Your build machine caches base images by default, and that cached image is used to build your app's image. This caching can result in out-of-date base images underneath your app in production.</Text>
      {/*<Text>You can temporarily avoid the out-of-date problem by including a <Text as="code">docker pull</Text> command in your build, but that just means your app is running "whatever was current as of the last build" - and you don't know what that is without some leg work.</Text>
      <Text>Even that temporary solution will eventually fail. When your app is feature complete (at least for now), it will no longer trigger builds, so any updates to your underlying OS or runtime are not applied to your production app.</Text>*/}
      <Text>With image-watch.com, you base your app on a specific version of your base image, and all updates are checked into source control. This means you <Text as="em">always</Text> know what's running in production. And even after your app is "done", you'll still be notified of important updates!</Text>

      <Heading>Reliable builds - for your teammates, too</Heading>
      <Text>Your local dev machine also caches base images. That means that you and your coworker can both be on the same commit and build the same Dockerfile, but end up with different runtime behavior. Usually this isn't a problem, but when it's a problem, it's a problem.</Text>
      <Text>When using image-watch.com, you reference a specific base image version in your source control, and if you and your coworker are on the same commit, then you will both build the same image with the same runtime behavior!</Text>

      <Heading>Simple, fair pricing</Heading>
      <Text>All subscriptions cost the same: $1/month. This "pays the rent" for our machines to poll your base image 24x7 and send your notifications.</Text>
      <Button as={GatsbyLink} to="/app">Get started!</Button> or <Button as={GatsbyLink} to="/docs">Read the docs</Button>
    </>
  );
};

export default Hero;